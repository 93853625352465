import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const Locations = props => {
  // const data = useStaticQuery(graphql`
  //   query ContactQuery {
  //       contactJson {
  //         phone
  //         phone1
  //         email
  //         email1
  //         contact_button_link
  //       }
  //   }
  //  `);
  return (

  <div class="container">
  <div class="row">
    <div class="col-md-6">
      <div class="card">
      <img class="card-img-top" src="/images/contact/Melbourne.png" alt="Melbourne Office"/>
        <div class="card-body">
          <h5 class="card-title">Melbourne Office</h5>
          <div class="email-section">
            <h6>Email</h6>
            <ul class="list-group list-group-flush">
              <li class="list-group-item"><i class="bi bi-envelope"></i> <a href = "mailto: rana@randjlawyers.com.au">rana@randjlawyers.com.au</a></li>
              <li class="list-group-item"><i class="bi bi-envelope"></i> <a href = "mailto: info@randjlawyers.com.au">info@randjlawyers.com.au</a></li>
            </ul>
          </div>
          <div class="phone-section">
            <h6>Phone</h6>
            <ul class="list-group list-group-flush">
              <li class="list-group-item"><i class="bi bi-telephone"></i><a href="tel:+61466042242" >0466 042 242</a></li>
            </ul>
          </div>
          <div class="address-section">
            <h6>Address</h6>
            <ul class="list-group list-group-flush">
              <li  class="list-group-item">Level 1, 445 Keilor Road, Niddrie, VIC, 3042</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card">
      <img class="card-img-top" src="/images/contact/Canberra.png" alt="Canberra Office"/>
        <div class="card-body">
          <h5 class="card-title">Canberra Office</h5>
          <div class="email-section">
            <h6>Email</h6>
            <ul class="list-group list-group-flush">
              <li  class="list-group-item"><i class="bi bi-envelope"></i><a href = "mailto: zahid@randjlawyers.com.au">zahid@randjlawyers.com.au</a></li>
              <li  class="list-group-item"><i class="bi bi-envelope"></i> <a href = "mailto: rana@randjlawyers.com.au">rana@randjlawyers.com.au</a></li>
              <li  class="list-group-item"><i class="bi bi-envelope"></i> <a href = "mailto: info@randjlawyers.com.au">info@randjlawyers.com.au</a></li>
            </ul>
          </div>
          <div class="phone-section">
            <h6>Phone</h6>
            <ul class="list-group list-group-flush">
              <li  class="list-group-item"><i class="bi bi-telephone"></i><a href="tel:+61430646897" >0430 646 897</a></li>
              <li  class="list-group-item"><i class="bi bi-telephone"></i><a href="tel:+61261854469" >02 6185 4469</a></li>
            </ul>
          </div>
          <div class="address-section">
            <h6>Address</h6>
            <ul class="list-group list-group-flush">
              <li  class="list-group-item">EY Building, Level 8, 121 Marcus Clarke Street Canberra, ACT, 2601</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
    // {/* <div class="card-group">
    //   <div class="card">
    //     <img class="card-img-top" src="/images/contact/Melbourne.png" alt="Melbourne Office"/>
    //     <div class="card-body">
    //       <h6 class="card-title">Melbourne Office</h6>
    //       <ul class="list-group list-group-flush">
    //     <li class="list-group-item">Email</li>
    //     <li class="list-group-item">Phone</li>
    //     <li class="list-group-item">Address</li>
    //   </ul>
    //     </div>
    //     <div class="card-footer">
    //       <small class="text-muted">Melbourne Office</small>
    //     </div>
    //   </div>
    //   <div class="card">
    //     <img class="card-img-top" src="/images/contact/Canberra.png" alt="Canberra Office"/>
    //     <div class="card-body">
    //       <h6 class="card-title">Canberra Office</h6>
    //       <ul class="list-group list-group-flush">
    //         <li class="list-group-item">Email: </li>
    //         <li class="list-group-item">Phone</li>
    //         <li class="list-group-item">Address</li>
    //       </ul>
    //     </div>
    //     <div class="card-footer">
    //       <small class="text-muted">Canberra Office</small>
    //     </div>
    //   </div>

    // </div> */}
        
  );
};

export default Locations;
